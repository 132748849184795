{
    "Oxygen": [
        [
            "f9ccca",
            "f08682",
            "e85752",
            "e20800",
            "bf0303",
            "9c0f0f"
        ],
        [
            "fcd9b0",
            "f2bb88",
            "f29b68",
            "ec7331",
            "cf4913",
            "ac4311"
        ],
        [
            "fff6c8",
            "fff299",
            "ffeb55",
            "ffdd00",
            "f3c300",
            "e3ad00"
        ],
        [
            "d8e8c2",
            "b1d28f",
            "77b753",
            "37a42c",
            "00892c",
            "006e29"
        ],
        [
            "99dcc6",
            "00cc88",
            "00b377",
            "009966",
            "00734d",
            "00593f"
        ],
        [
            "a8dde0",
            "00c4cc",
            "00a7b3",
            "007880",
            "006066",
            "00484d"
        ],
        [
            "a4c0e4",
            "6193cf",
            "2c72c7",
            "0057ae",
            "00438a",
            "00316e"
        ],
        [
            "c3b4da",
            "8e79b9",
            "644a9b",
            "462886",
            "34176e",
            "1d0a55"
        ],
        [
            "e8b7d7",
            "c173b0",
            "b14f9a",
            "a02786",
            "85026c",
            "6a0056"
        ],
        [
            "f9cade",
            "f082b0",
            "e85290",
            "e20071",
            "bf0361",
            "9c0f56"
        ],
        [
            "debc85",
            "b3925d",
            "8f6b32",
            "75511a",
            "57401e",
            "382509"
        ],
        [
            "eeeeec",
            "d3d7cf",
            "babdb6",
            "888a85",
            "555753",
            "2e3436"
        ]
    ],
    "Solarized": [
        [
            "002b36",
            "073642",
            "586e75",
            "657b83",
            "839496",
            "93a1a1",
            "eee8d5",
            "fdf6e3"
        ],
        [
            "b58900",
            "cb4b16",
            "dc322f",
            "d33682",
            "6c71c4",
            "268bd2",
            "2aa198",
            "859900"
        ]
    ],
    "Gruvbox": [
        [
            "282828",
            "cc241d",
            "98971a",
            "d79921",
            "458588",
            "b16286",
            "689d6a",
            "a89984"
        ],
        [
            "928374",
            "fb4934",
            "b8bb26",
            "fabd2f",
            "83a598",
            "d3869b",
            "8ec07c",
            "ebdbb2"
        ],
        [
            "1d2021",
            "282828",
            "3c3836",
            "504945",
            "665c54",
            "7c6f64",
            "928374",
            "d65d0e"
        ],
        [
            "000000",
            "32302f",
            "a89984",
            "bdae93",
            "d5c4a1",
            "ebdbb2",
            "fbf1c7",
            "fe8019"
        ],
        [
            "fbf1c7",
            "cc241d",
            "98971a",
            "d79921",
            "458588",
            "b16286",
            "689d6a",
            "7c6f64"
        ],
        [
            "928374",
            "9d0006",
            "79740e",
            "b57614",
            "076678",
            "8f3f71",
            "427b58",
            "3c3836"
        ],
        [
            "f9f5d7",
            "fbf1c7",
            "ebdbb2",
            "d5c4a1",
            "bdae93",
            "a89984",
            "928374",
            "d65d0e"
        ],
        [
            "000000",
            "f2e5bc",
            "7c6f64",
            "665c54",
            "504945",
            "3c3836",
            "282828",
            "af3a03"
        ]
    ],
    "Colorbrewer": [
        [
            "ffffe5",
            "f7fcb9",
            "d9f0a3",
            "addd8e",
            "78c679",
            "41ab5d",
            "238443",
            "006837",
            "004529"
        ],
        [
            "ffffd9",
            "edf8b1",
            "c7e9b4",
            "7fcdbb",
            "41b6c4",
            "1d91c0",
            "225ea8",
            "253494",
            "081d58"
        ],
        [
            "f7fcf0",
            "e0f3db",
            "ccebc5",
            "a8ddb5",
            "7bccc4",
            "4eb3d3",
            "2b8cbe",
            "0868ac",
            "084081"
        ],
        [
            "f7fcfd",
            "e5f5f9",
            "ccece6",
            "99d8c9",
            "66c2a4",
            "41ae76",
            "238b45",
            "006d2c",
            "00441b"
        ],
        [
            "fff7fb",
            "ece2f0",
            "d0d1e6",
            "a6bddb",
            "67a9cf",
            "3690c0",
            "02818a",
            "016c59",
            "014636"
        ],
        [
            "fff7fb",
            "ece7f2",
            "d0d1e6",
            "a6bddb",
            "74a9cf",
            "3690c0",
            "0570b0",
            "045a8d",
            "023858"
        ],
        [
            "f7fcfd",
            "e0ecf4",
            "bfd3e6",
            "9ebcda",
            "8c96c6",
            "8c6bb1",
            "88419d",
            "810f7c",
            "4d004b"
        ],
        [
            "fff7f3",
            "fde0dd",
            "fcc5c0",
            "fa9fb5",
            "f768a1",
            "dd3497",
            "ae017e",
            "7a0177",
            "49006a"
        ],
        [
            "f7f4f9",
            "e7e1ef",
            "d4b9da",
            "c994c7",
            "df65b0",
            "e7298a",
            "ce1256",
            "980043",
            "67001f"
        ],
        [
            "fff7ec",
            "fee8c8",
            "fdd49e",
            "fdbb84",
            "fc8d59",
            "ef6548",
            "d7301f",
            "b30000",
            "7f0000"
        ],
        [
            "ffffcc",
            "ffeda0",
            "fed976",
            "feb24c",
            "fd8d3c",
            "fc4e2a",
            "e31a1c",
            "bd0026",
            "800026"
        ],
        [
            "ffffe5",
            "fff7bc",
            "fee391",
            "fec44f",
            "fe9929",
            "ec7014",
            "cc4c02",
            "993404",
            "662506"
        ],
        [
            "fcfbfd",
            "efedf5",
            "dadaeb",
            "bcbddc",
            "9e9ac8",
            "807dba",
            "6a51a3",
            "54278f",
            "3f007d"
        ],
        [
            "f7fbff",
            "deebf7",
            "c6dbef",
            "9ecae1",
            "6baed6",
            "4292c6",
            "2171b5",
            "08519c",
            "08306b"
        ],
        [
            "f7fcf5",
            "e5f5e0",
            "c7e9c0",
            "a1d99b",
            "74c476",
            "41ab5d",
            "238b45",
            "006d2c",
            "00441b"
        ],
        [
            "fff5eb",
            "fee6ce",
            "fdd0a2",
            "fdae6b",
            "fd8d3c",
            "f16913",
            "d94801",
            "a63603",
            "7f2704"
        ],
        [
            "fff5f0",
            "fee0d2",
            "fcbba1",
            "fc9272",
            "fb6a4a",
            "ef3b2c",
            "cb181d",
            "a50f15",
            "67000d"
        ],
        [
            "ffffff",
            "f0f0f0",
            "d9d9d9",
            "bdbdbd",
            "969696",
            "737373",
            "525252",
            "252525",
            "000000"
        ],
        [
            "7f3b08",
            "b35806",
            "e08214",
            "fdb863",
            "fee0b6",
            "f7f7f7",
            "d8daeb",
            "b2abd2",
            "8073ac",
            "542788",
            "2d004b"
        ],
        [
            "543005",
            "8c510a",
            "bf812d",
            "dfc27d",
            "f6e8c3",
            "f5f5f5",
            "c7eae5",
            "80cdc1",
            "35978f",
            "01665e",
            "003c30"
        ],
        [
            "40004b",
            "762a83",
            "9970ab",
            "c2a5cf",
            "e7d4e8",
            "f7f7f7",
            "d9f0d3",
            "a6dba0",
            "5aae61",
            "1b7837",
            "00441b"
        ],
        [
            "8e0152",
            "c51b7d",
            "de77ae",
            "f1b6da",
            "fde0ef",
            "f7f7f7",
            "e6f5d0",
            "b8e186",
            "7fbc41",
            "4d9221",
            "276419"
        ],
        [
            "67001f",
            "b2182b",
            "d6604d",
            "f4a582",
            "fddbc7",
            "f7f7f7",
            "d1e5f0",
            "92c5de",
            "4393c3",
            "2166ac",
            "053061"
        ],
        [
            "67001f",
            "b2182b",
            "d6604d",
            "f4a582",
            "fddbc7",
            "ffffff",
            "e0e0e0",
            "bababa",
            "878787",
            "4d4d4d",
            "1a1a1a"
        ],
        [
            "a50026",
            "d73027",
            "f46d43",
            "fdae61",
            "fee090",
            "ffffbf",
            "e0f3f8",
            "abd9e9",
            "74add1",
            "4575b4",
            "313695"
        ],
        [
            "9e0142",
            "d53e4f",
            "f46d43",
            "fdae61",
            "fee08b",
            "ffffbf",
            "e6f598",
            "abdda4",
            "66c2a5",
            "3288bd",
            "5e4fa2"
        ],
        [
            "a50026",
            "d73027",
            "f46d43",
            "fdae61",
            "fee08b",
            "ffffbf",
            "d9ef8b",
            "a6d96a",
            "66bd63",
            "1a9850",
            "006837"
        ]
    ],
    "6-bit Colors": [
        [
            "000000",
            "550000",
            "aa0000",
            "ff0000",
            "005500",
            "555500",
            "aa5500",
            "ff5500"
        ],
        [
            "000055",
            "550055",
            "aa0055",
            "ff0055",
            "005555",
            "555555",
            "aa5555",
            "ff5555"
        ],
        [
            "0000aa",
            "5500aa",
            "aa00aa",
            "ff00aa",
            "0055aa",
            "5555aa",
            "aa55aa",
            "ff55aa"
        ],
        [
            "0000ff",
            "5500ff",
            "aa00ff",
            "ff00ff",
            "0055ff",
            "5555ff",
            "aa55ff",
            "ff55ff"
        ],
        [
            "00aa00",
            "55aa00",
            "aaaa00",
            "ffaa00",
            "00ff00",
            "55ff00",
            "aaff00",
            "ffff00"
        ],
        [
            "00aa55",
            "55aa55",
            "aaaa55",
            "ffaa55",
            "00ff55",
            "55ff55",
            "aaff55",
            "ffff55"
        ],
        [
            "00aaaa",
            "55aaaa",
            "aaaaaa",
            "ffaaaa",
            "00ffaa",
            "55ffaa",
            "aaffaa",
            "ffffaa"
        ],
        [
            "00aaff",
            "55aaff",
            "aaaaff",
            "ffaaff",
            "00ffff",
            "55ffff",
            "aaffff",
            "ffffff"
        ]
    ]
}
